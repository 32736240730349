import type { FC } from 'react';
import styled from '@emotion/styled';
import { Icon } from '@pafcloud/base-components';
import { Color } from '@pafcloud/style';
import type { GameItemSize } from './game-item-styles/gameItemSharedStyles';

const TurnoverBadgeContainer = styled.div<{ size: GameItemSize }>(({ size }) => ({
  background: Color.Primitive.Gradient,
  borderRadius: 999,
  position: 'absolute',
  top: '0',
  left: '50%',
  transform: size === 'small' ? 'translate(-50%, -50%) scale(0.7)' : 'translate(-50%, -50%)',
  transition: '0.2s',
  zIndex: 1,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  padding: '2px 12px',
  boxShadow: `0 0 5px ${Color.Primitive.Gradient}cc`,
  color: Color.Primitive.GradientContrast,
}));

const Text = styled.span({
  marginRight: 4,
  padding: '2px 0',
  fontWeight: 'bold',
});

type TurnoverBadgeProps = {
  turnoverContribution: number;
  size: GameItemSize;
};

export const TurnoverBadge: FC<TurnoverBadgeProps> = ({ turnoverContribution, size }) => {
  return (
    <TurnoverBadgeContainer size={size}>
      <Text>{turnoverContribution}%</Text>
      <Icon name="turnoverContribution" size="1.2rem" />
    </TurnoverBadgeContainer>
  );
};
