/**
 * @generated SignedSource<<d7bd797ed73ca63bab89fc3da23aef72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShowTotalGameCount_block$data = {
  readonly showTotalGameCount: boolean | null;
  readonly " $fragmentType": "ShowTotalGameCount_block";
};
export type ShowTotalGameCount_block$key = {
  readonly " $data"?: ShowTotalGameCount_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowTotalGameCount_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowTotalGameCount_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showTotalGameCount",
      "storageKey": null
    }
  ],
  "type": "CommonGameListBlock",
  "abstractKey": "__isCommonGameListBlock"
};

(node as any).hash = "f7bb6d31dd85a3bf3eab0a69d18a7d83";

export default node;
