/**
 * @generated SignedSource<<91543d09e0cee65a0c76b9c335c3f969>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameBadge_game$data = {
  readonly familyName: string;
  readonly new: boolean;
  readonly " $fragmentType": "GameBadge_game";
};
export type GameBadge_game$key = {
  readonly " $data"?: GameBadge_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"GameBadge_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GameBadge_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "new",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "8209c0774345afa64248b50b0c499367";

export default node;
