import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Icon, InternalLink } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { rememberGameHomeUrl } from '@pafcloud/react-hook-utils';
import { useInViewTracking, useHasActiveTurnoverStep } from '@pafcloud/contexts';
import type { GameClickedProps, GameViewedProps } from '@pafcloud/contexts';
import { useGetTurnoverContribution } from '../../game-item';
import { FlexibleGameListColors } from '../flexible-game-list-colors';
import type { GameLink_game$key } from './__generated__/GameLink_game.graphql';

const gameFragment = graphql`
  fragment GameLink_game on Game {
    launchUrl
    status
    name
    ...useGetTurnoverContribution_game
    ...useTrackGame_game
  }
`;

const GameLinkStyle = styled(InternalLink)({
  outline: 'transparent',
});

const DisabledGame = styled.div({
  position: 'relative',
});

const MaintenanceIcon = styled(Icon)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1,
  transform: 'translate(-50%, -50%)',
  color: FlexibleGameListColors.MaintenanceText,
  fontSize: 'clamp(1.5rem, 5vw, 2.5rem)',
  filter: 'drop-shadow(0 2px 9px rgba(0, 0, 0, 0.7))',
});

type GameLinkProps = {
  children?: ReactNode;
  onClick: (props: GameClickedProps) => void;
  onView: (props: GameViewedProps) => void;
  index: number;
  game: GameLink_game$key;
};

export const GameLink: FC<GameLinkProps> = ({ children, index, onClick, onView, ...props }) => {
  const game = useFragment(gameFragment, props.game);
  const { t } = useTranslation('game-list');

  const hasActiveTurnover = useHasActiveTurnoverStep();
  const turnoverContribution = useGetTurnoverContribution(game);
  const disabledDueToTurnover = hasActiveTurnover && turnoverContribution == null;
  const inMaintenance = game.status === 'MAINTENANCE';

  const trackRef = useInViewTracking((entry) => {
    onView({ game, gameListPosition: index, entry });
  });

  const handleClick = () => {
    onClick({ game, gameListPosition: index });
    rememberGameHomeUrl();
  };

  if (disabledDueToTurnover || inMaintenance) {
    return (
      <DisabledGame aria-disabled="true" aria-label={game.name} title={t('badge.title.maintenance')}>
        {inMaintenance && <MaintenanceIcon name="maintenance" size="1em" />}
        {children}
      </DisabledGame>
    );
  }

  return (
    <GameLinkStyle href={game.launchUrl} onClick={handleClick} ref={trackRef} aria-label={game.name}>
      {children}
    </GameLinkStyle>
  );
};
