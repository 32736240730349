import styled from '@emotion/styled';
import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Icon } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { useHasActiveTurnoverStep, useMatchGameToBingoTickets, useMatchGameToFreespins } from '@pafcloud/contexts';
import { useGetTurnoverContribution } from '../../game-item';
import { PartsZIndex } from '../definitions';
import { FlexibleGameListColors } from '../flexible-game-list-colors';
import type { TurnoverBonus_game$key } from './__generated__/TurnoverBonus_game.graphql';
import { FullCover } from './shared';

const turnoverBonusFragment = graphql`
  fragment TurnoverBonus_game on Game {
    ...useGetTurnoverContribution_game
    familyName
  }
`;

const Turnover = styled(FullCover)({
  zIndex: PartsZIndex.Turnover,
  pointerEvents: 'none',
  border: FlexibleGameListColors.TurnoverBorder,
  boxShadow: FlexibleGameListColors.TurnoverShadow,
  borderRadius: 'var(--radius)',
});
const MultiplayerBingoTurnover = styled(Turnover)({
  inset: 0,
  borderRadius: 'var(--card-radius)',
});

const TurnoverBadge = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: -1,
  right: -1,
  padding: `8px max(0.5em, calc(var(--radius) / 2)) 8px 0.5em`,
  pointerEvents: 'all',
  borderRadius: `0 var(--radius) 0 8px`,
  background: FlexibleGameListColors.TurnoverBadgeBackground,
  color: FlexibleGameListColors.TurnoverBadgeText,
  textAlign: 'center',
  textIndent: 4,
  fontSize: 'clamp(10px, 1.5vw, 12px)',
  fontWeight: 'bold',
  textShadow: FlexibleGameListColors.TurnoverBadgeTextShadow,
});
const MultiplayerBingoTurnoverBadge = styled(TurnoverBadge)({
  padding: '8px max(0.5em, calc(var(--card-radius) / 2)) 8px 0.5em',
  borderRadius: '0 var(--card-radius) 0 8px',
});

const TurnoverIcon = styled(Icon)({
  marginLeft: 4,
  marginTop: -1,
  verticalAlign: 'middle',
});

type TurnoverBonusProps = {
  game: TurnoverBonus_game$key;
  isMultiplayer?: boolean;
};

export const TurnoverBonus: FC<TurnoverBonusProps> = (props) => {
  const { t } = useTranslation('game-list');
  const game = useFragment(turnoverBonusFragment, props.game);
  const hasActiveTurnover = useHasActiveTurnoverStep();
  const turnoverContribution = useGetTurnoverContribution(game);
  const showTurnover = hasActiveTurnover && !!turnoverContribution;

  /* If the game has freespins or bingo tickets, do not render Turnover */
  const hasBingoTickets = !!useMatchGameToBingoTickets(game.familyName);
  const hasFreespins = !!useMatchGameToFreespins(game.familyName);

  if (!showTurnover || hasFreespins || hasBingoTickets) {
    return null;
  }

  const TurnoverGlow = props.isMultiplayer ? MultiplayerBingoTurnover : Turnover;
  const TurnoverGlowBadge = props.isMultiplayer ? MultiplayerBingoTurnoverBadge : TurnoverBadge;

  return (
    <TurnoverGlow>
      <TurnoverGlowBadge title={t('badge.title.turnover')}>
        {turnoverContribution}%
        <TurnoverIcon name="turnoverContribution" size="1.2em" />
      </TurnoverGlowBadge>
    </TurnoverGlow>
  );
};
