/**
 * @generated SignedSource<<e7cf9d6aa705eaa7ce3bfde7fb8ed9b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameList_trackable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_trackable">;
  readonly " $fragmentType": "GameList_trackable";
};
export type GameList_trackable$key = {
  readonly " $data"?: GameList_trackable$data;
  readonly " $fragmentSpreads": FragmentRefs<"GameList_trackable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GameList_trackable",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useTrackGame_trackable",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "track",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackId",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Trackable",
  "abstractKey": "__isTrackable"
};

(node as any).hash = "fe6b7392f0ad406ad396f5761af8f90e";

export default node;
