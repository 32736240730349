/**
 * @generated SignedSource<<ecd2a3d491406dfd99de0806366c5e15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaginatedGridGameListQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  id: string;
};
export type PaginatedGridGameListQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"GridGameList_gameList">;
  } | null;
};
export type PaginatedGridGameListQuery = {
  response: PaginatedGridGameListQuery$data;
  variables: PaginatedGridGameListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 40,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaginatedGridGameListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "GridGameList_gameList"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaginatedGridGameListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "GameListGames_Connection",
                "kind": "LinkedField",
                "name": "games",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "recommendationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "recommendationGroupId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GameEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Game",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "launchUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "turnoverContribution",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "category",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "marketGameCategory",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "familyName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "gameId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "provider",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Jackpot",
                            "kind": "LinkedField",
                            "name": "jackpot",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BetInfo",
                            "kind": "LinkedField",
                            "name": "betInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minBet",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxBet",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "new",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "logo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "supplier",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "layout",
                                "value": "PORTRAIT"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "thumbnail",
                            "storageKey": "thumbnail(layout:\"PORTRAIT\")"
                          },
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "PaginatedGridGameList_GameList_games",
                "kind": "LinkedHandle",
                "name": "games"
              }
            ],
            "type": "GameList",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cbe49bb6616e6c4aecc8bb118cb66c9f",
    "id": null,
    "metadata": {},
    "name": "PaginatedGridGameListQuery",
    "operationKind": "query",
    "text": "query PaginatedGridGameListQuery(\n  $count: Int = 40\n  $cursor: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...GridGameList_gameList_1G22uz\n    id\n  }\n}\n\nfragment Decorations_game on Game {\n  ...MonetaryDetails_game\n  ...GameBadge_game\n  ...TurnoverBonus_game\n}\n\nfragment GameBadge_game on Game {\n  new\n  familyName\n}\n\nfragment GameLink_game on Game {\n  launchUrl\n  status\n  name\n  ...useGetTurnoverContribution_game\n  ...useTrackGame_game\n}\n\nfragment GameLogo_game on Game {\n  logo\n}\n\nfragment GridGameList_gameList_1G22uz on GameList {\n  games(first: $count, after: $cursor) {\n    ...useTrackGame_games\n    ...ShowTotalGameCount_games\n    edges {\n      node {\n        ...GameLink_game\n        ...Decorations_game\n        ...GameLogo_game\n        name\n        supplier\n        slug\n        thumbnail(layout: PORTRAIT)\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment MonetaryDetails_game on Game {\n  jackpot {\n    amount\n  }\n  betInfo {\n    minBet\n    maxBet\n  }\n}\n\nfragment ShowTotalGameCount_games on GameListGames_Connection {\n  totalCount\n}\n\nfragment TurnoverBonus_game on Game {\n  ...useGetTurnoverContribution_game\n  familyName\n}\n\nfragment useGetTurnoverContribution_game on Game {\n  turnoverContribution\n  category\n  marketGameCategory\n}\n\nfragment useTrackGame_game on Game {\n  category\n  familyName\n  gameId\n  launchUrl\n  name\n  provider\n  slug\n}\n\nfragment useTrackGame_games on GameListGames_Connection {\n  recommendationId\n  recommendationGroupId\n}\n"
  }
};
})();

(node as any).hash = "4765300abc14eae98b46b82c50b74f9b";

export default node;
