/**
 * @generated SignedSource<<0320ad14a6bfcd2c7ed4c6e6db02704a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterGridGameList_trackable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_trackable">;
  readonly " $fragmentType": "FilterGridGameList_trackable";
};
export type FilterGridGameList_trackable$key = {
  readonly " $data"?: FilterGridGameList_trackable$data;
  readonly " $fragmentSpreads": FragmentRefs<"FilterGridGameList_trackable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FilterGridGameList_trackable",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useTrackGame_trackable",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "track",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackId",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Trackable",
  "abstractKey": "__isTrackable"
};

(node as any).hash = "e491b513cd6332d0a03e6e808d9763f4";

export default node;
