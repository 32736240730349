/**
 * @generated SignedSource<<b1b68b8a323824d952829ef771c17ec0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Decorations_game$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GameBadge_game" | "MonetaryDetails_game" | "TurnoverBonus_game">;
  readonly " $fragmentType": "Decorations_game";
};
export type Decorations_game$key = {
  readonly " $data"?: Decorations_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"Decorations_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Decorations_game",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MonetaryDetails_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GameBadge_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TurnoverBonus_game"
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "7dcf492d601b430955814b9aa7dacc25";

export default node;
