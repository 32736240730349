import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  HighlightGameListFrameBorder: Color.Primitive.Gradient,
  HighlightGameListHeaderText: Color.BodyText,

  NumberedGameListCounterText: Color.Primitive.Gradient,
} as const;
