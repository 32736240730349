import { graphql, useFragment } from 'react-relay/hooks';
import { isType } from '@pafcloud/collection-utils';
import { useBonus } from '@pafcloud/contexts';
import { upperCase } from '@pafcloud/util';
import type { useGetTurnoverContribution_game$key } from './__generated__/useGetTurnoverContribution_game.graphql';

const useGetTurnoverContributionGame = graphql`
  fragment useGetTurnoverContribution_game on Game {
    turnoverContribution
    category
    marketGameCategory
  }
`;

export const useGetTurnoverContribution = (props: useGetTurnoverContribution_game$key): number | null => {
  const game = useFragment(useGetTurnoverContributionGame, props);
  const { turnover } = useBonus();

  if (game == null) {
    return null;
  }

  const { category, marketGameCategory: pintGameCategory, turnoverContribution } = game;

  // category comes as lower case, while PBS and PINT sends it as uppercase
  const cmsGameCategory = upperCase(category);

  // null and 0 turnover contribution on a game, overrides the turnover contribution at category level.
  if (game.turnoverContribution == null || game.turnoverContribution === 0) {
    return null;
  }

  /**
   * Expected behaviour:
   * - If the game has its category in PINT set to the same as the category in Sanity we should
   * use the turnoverContribution set through PBS, with the exception of 0-cases which handled above.
   * - If the game category in PINT differs from the one set in Sanity, we should treat it as an
   * anomaly and instead use the turnoverContribution set for that specific game.
   */

  if (cmsGameCategory !== pintGameCategory) {
    return turnoverContribution;
  }

  const contribution = isType(turnover?.step, 'TurnoverOfferStep')
    ? turnover.step.turnoverCategories?.find((turnoverCategory) => {
        return turnoverCategory.category === cmsGameCategory;
      })
    : null;

  if (!contribution?.contributionPercentage) {
    return null;
  }

  return turnoverContribution;
};
