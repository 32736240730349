/**
 * @generated SignedSource<<268d886978c02748e844b79ccc8d0390>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGetTurnoverContribution_game$data = {
  readonly category: string;
  readonly marketGameCategory: string;
  readonly turnoverContribution: number | null;
  readonly " $fragmentType": "useGetTurnoverContribution_game";
};
export type useGetTurnoverContribution_game$key = {
  readonly " $data"?: useGetTurnoverContribution_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGetTurnoverContribution_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGetTurnoverContribution_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "turnoverContribution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "marketGameCategory",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "9f885b9ae5cf5546b91b9915d729debd";

export default node;
