/**
 * @generated SignedSource<<8b4aec89f25349317ddf87a7e737eb09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HighlightGameList_block$data = {
  readonly gameList: {
    readonly games: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly name: string;
          readonly slug: string;
          readonly thumbnailLandscape: string;
          readonly thumbnailPortrait: string;
          readonly " $fragmentSpreads": FragmentRefs<"Decorations_game" | "GameLink_game">;
        };
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"ShowTotalGameCount_games" | "useTrackGame_games">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_gameList">;
  };
  readonly gameListBackground: {
    readonly " $fragmentSpreads": FragmentRefs<"GameListBackground">;
  } | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_trackable">;
  readonly " $fragmentType": "HighlightGameList_block";
};
export type HighlightGameList_block$key = {
  readonly " $data"?: HighlightGameList_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"HighlightGameList_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HighlightGameList_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GameList",
      "kind": "LinkedField",
      "name": "gameList",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useTrackGame_gameList",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gameListId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalName",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 3
            }
          ],
          "concreteType": "GameListGames_Connection",
          "kind": "LinkedField",
          "name": "games",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useTrackGame_games",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recommendationId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recommendationGroupId",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShowTotalGameCount_games"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GameEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Game",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "GameLink_game"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Decorations_game"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    },
                    {
                      "alias": "thumbnailLandscape",
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "layout",
                          "value": "LANDSCAPE"
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "thumbnail",
                      "storageKey": "thumbnail(layout:\"LANDSCAPE\")"
                    },
                    {
                      "alias": "thumbnailPortrait",
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "layout",
                          "value": "PORTRAIT"
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "thumbnail",
                      "storageKey": "thumbnail(layout:\"PORTRAIT\")"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "games(first:3)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useTrackGame_trackable",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "track",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trackId",
              "storageKey": null
            }
          ],
          "type": "Trackable",
          "abstractKey": "__isTrackable"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GameListBackground",
      "kind": "LinkedField",
      "name": "gameListBackground",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GameListBackground"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HighlightGameListBlock",
  "abstractKey": null
};

(node as any).hash = "d81f7a5357b264049d818dd7436a94b4";

export default node;
